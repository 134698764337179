import {Alert, Spin} from 'antd'
import React from 'react'

interface CustomSpinProps {
    children: React.ReactNode
    loading: boolean | string
    message?: string
}

const CustomSpin: React.FC<CustomSpinProps> = (
    {
        children,
        loading,
        message = 'Internal app error. Please contact support.'
    }) => {
    const loading_bool = loading === true
    return (
        <>
            {loading === 'error'
                ? <Alert
                    message={message}
                    style={{
                        textAlign: 'center',
                        height: '10vh',
                        width: '20vw'
                    }}

                    type='error'
                    showIcon
                />
                : loading === 'init' || loading
                    ? <Spin size='large'/>
                    : children}
        </>
    )
}

export default CustomSpin
