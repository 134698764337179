import {Button, ConfigProvider, Divider, Layout, Menu} from 'antd'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import './LayoutWrapper.css'
import Sider from 'antd/es/layout/Sider'
import {backgroundColor, colorPrimary} from '../global'
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from '../utils/firebase'

import {LogoutOutlined, UserOutlined} from '@ant-design/icons'
import backgroundImage from '../icons/gen_background3.png';

interface LayoutWrapperProps {
    component: React.ComponentType
}

const links = ['homepage', 'model-description', 'budget-allocation', 'refresh']

const linkItems = links.map(
    link => ({
        label:
            <Link to={`/${link}`} style={{}}>
                <Button
                    type='text' style={{
                    width: '100%',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'start'
                }}
                >

                    {link.replace('-', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}

                </Button>
            </Link>

    })).concat(
    [
        {
            label: <Divider
                style={{backgroundColor: 'white'}}
            />

        }
    ]
)

const userItems = [
    {
        label:
            <Button
                shape='circle' icon={
                <UserOutlined
                    style={{fontSize: '15px'}}
                />
            }
                style={{height: '40px', width: '40px'}}
            />,

        key: 'logout-mail',
        children: [
            {
                label:
                    <div>
                        User configuration
                    </div>,
                key: 'mail'
            },
            {
                label:
                    <Link to='/login'>
                        <Button
                            style={{
                                borderColor: 'transparent'
                            }}
                            icon={<LogoutOutlined/>}
                        >
                            Log out
                        </Button>
                    </Link>,
                key: 'logout'
            }
        ]
    }
]

interface CustomSiderProps {
    items?: []

}

export const CustomSider: React.FC<any> = ({items = null}) => {
    let allItems = linkItems
    if (items !== null) {
        allItems = allItems.concat(
            items)
    }

    return (
        <Sider
            width='15%'
            style={{
                backgroundColor: colorPrimary
            }}
        >
            <div
                style={{
                    height: '100vh'
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'transparent',
                    height: '60%'

                }}
                >

                    {allItems.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                marginRight: '15px',
                                marginLeft: '15px',
                                margin: '5px'
                            }}
                        >
                            {item?.label}
                        </div>
                    ))}

                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    height: '40%'

                }}
                >
                    <Menu
                        mode='horizontal'

                        items={userItems}
                        style={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            marginBottom: '10px'
                        }}
                    />

                </div>
            </div>
        </Sider>
    )
}
const LayoutWrapper: React.FC<LayoutWrapperProps> = (
    {

        component: WrappedComponent
    }) => {
    const location = useLocation()
    let title = location.pathname.substring(1)
    if (title === '') {
        title = 'homepage'
    }
    console.log({title})
    const title_key = title.charAt(0).toUpperCase() + title.slice(1)

    const navigate = useNavigate()
    const [login, setLogin] = useState(false)
    console.log({login})
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                if (!['intro', 'plans', 'signup', 'login'].includes(title)) {
                    // navigate('/intro')
                    navigate('/login')
                }
            } else {
                if (['intro'].includes(title)) {
                    navigate('/')
                }
            }
            setLogin(true)
        })
    }, [auth, navigate, title])

    return (
        <ConfigProvider
            theme={{
                token: {
                    // colorPrimary: '#00b96b',
                    colorPrimary,
                    borderRadius: 4,
                    fontFamily: 'Roboto'
                    // colorBgContainer: '#f6ffed',
                }

            }}
        >
            <Layout
                className='layout content' style={{
                backgroundColor: backgroundColor,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: '950px',

                backgroundRepeat: 'repeat',
                animation: 'fadeInAnimation 1s linear'
            }}
            >
                {login &&
                    <WrappedComponent/>}
            </Layout>
        </ConfigProvider>
    )
}

export default LayoutWrapper
