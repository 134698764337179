import React, {useEffect, useState} from 'react';
import {Button, Col, Collapse, CollapseProps, Form, Input, Layout, Row, Switch} from 'antd';
import {useLocation, useNavigate} from "react-router-dom"
import CustomSlider from "./components/CustomSlider"
import {fetchBackendToken} from "./utils/backend"
import CustomSpin from "./components/CustomSpin"
import Markdown from 'react-markdown'
import {saveAs} from 'file-saver';
import {ReactComponent as Logo} from './icons/base_logo_light.svg'
import {colorPrimary, colorSecondary, colorTextSecondary} from "./global"
import {CaretRightOutlined} from '@ant-design/icons';
import LogOut from "./components/LogOut"

const {Header, Footer, Sider, Content} = Layout

export const useNavigateHome = (page_link = '') => {
    const location = useLocation()
    let page_title = location.pathname.substring(1)
    const navigate = useNavigate()
    console.log({page_title})

    useEffect(() => {
        if (page_title !== page_link) {
            console.log('redirecting')
            navigate(page_link)
        }
    }, [page_title])
}

interface FormSequenceProps {
    form: any
    label: string
    name: string
    help: string
    default_value: string
}

const FormSequence: React.FC<any> = (
    {form, label, name, help, default_value}
) => {
    const span = 20
    return (

        <Row>
            <Col span={span}>
                <Form.Item label={label} name={name}
                           rules={[{
                               required: true, message: 'Input required',
                           },
                               {
                                   validator: (_, value) => {
                                       const regex = /^[ACGTU]*$/;
                                       if (!value || value === '') {
                                           return Promise.resolve()
                                       } else
                                           return regex.test(value) ? Promise.resolve() : Promise.reject(new Error('MRNA sequence should only contain letters ACGTU'))
                                   },
                               }
                           ]}
                >

                    <Input
                        placeholder={help}

                    />
                </Form.Item>
            </Col>
            <Col span={24 - span}
                 style={{
                     display: 'flex',
                     // justifyContent: 'center',
                     alignItems: 'center',
                     paddingLeft: '10px',
                     paddingTop: '5px'
                 }}

            >
                <Button
                    style={{width: '100%'}}
                    onClick={() => {
                        form.setFieldValue(name, default_value)
                    }}

                >
                    sample
                </Button>
            </Col>
        </Row>


    )
}


const FormStructure: React.FC<any> = (
    {form, label, name, help, default_value}
) => {
    const span = 20
    return (

        <Row>
            <Col span={span}>
                <Form.Item label={label} name={name}
                           rules={[{
                               required: true, message: 'Input required',
                           },
                               {
                                   validator: (_, value) => {
                                       const regex = /^[().]*$/
                                       if (!value || value === '') {
                                           return Promise.resolve()
                                       } else
                                           return regex.test(value) ? Promise.resolve() : Promise.reject(new Error('MRNA structure should only contain letters "(", ")", "."'))
                                   },
                               }
                           ]}
                >

                    <Input
                        placeholder={help}

                    />
                </Form.Item>
            </Col>
            <Col span={24 - span}
                 style={{
                     display: 'flex',
                     // justifyContent: 'center',
                     alignItems: 'center',
                     paddingLeft: '10px',
                     paddingTop: '5px'
                 }}

            >
                <Button
                    style={{width: '100%'}}
                    onClick={() => {
                        form.setFieldValue(name, default_value)
                    }}

                >
                    sample
                </Button>
            </Col>
        </Row>


    )
}

interface FormIntProps {
    form: any
    label: string,
    name: string,
    default_value: number,
    min: number,
    max: number,
    step: number
}

const FormNumber: React.FC<any> = (
    {
        form,
        label,
        name,
        default_value,
        min,
        max,
        step = 1
    }
) => {

    return (
        <Form.Item label={label} name={name}
                   initialValue={default_value}


        >

            <CustomSlider
                min={min}
                max={max}
                step={step}


            />
        </Form.Item>
    )
}

// const FormFloat


interface FormBooleanProps {
    form: any,
    default_value: boolean
    name: string
    label: string
}

const FormBoolean: React.FC<any> = (
    {
        form,
        name,
        label,
        default_value = false
    }
) => {

    return (
        <Form.Item label={label} name={name}
                   initialValue={default_value}

        >

            <Switch/>
        </Form.Item>
    )
}

interface FormMapProps {
    form: any
    defaultValues: Array<any>
}

const FormMap: React.FC<FormMapProps> = (
    {
        form,
        defaultValues
    }
) => {

    return (
        <Form
            form={form}
            layout='vertical'
            style={{
                backgroundColor: 'white',
                borderRadius: 4,
            }}
        >

            {Array.from({length: Math.ceil(defaultValues.length / 2)}, (_, i) => i * 2).map(i => {
                return (
                    <Row key={i} gutter={40}>
                        <Col span={12}>
                            {defaultValues[i].type === 'sequence' && <FormSequence form={form} {...defaultValues[i]} />}
                            {defaultValues[i].type === 'structure' &&
                                <FormStructure form={form} {...defaultValues[i]} />}

                            {defaultValues[i].type === 'number' && <FormNumber form={form} {...defaultValues[i]} />}
                            {defaultValues[i].type === 'boolean' && <FormBoolean form={form} {...defaultValues[i]} />}
                        </Col>
                        <Col span={12}>
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'sequence' &&
                                <FormSequence form={form} {...defaultValues[i + 1]} />}
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'structure' &&
                                <FormStructure form={form} {...defaultValues[i + 1]} />}
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'number' &&
                                <FormNumber form={form} {...defaultValues[i + 1]} />}
                            {defaultValues[i + 1] && defaultValues[i + 1].type === 'boolean' &&
                                <FormBoolean form={form} {...defaultValues[i + 1]} />}
                        </Col>
                    </Row>
                );
            })}


        </Form>
    )
}
const initDefaultValues = {
    design_options: [],
    parameters: [],
    output_options: []

}

const outputOptions = [
    {
        type: 'boolean',
        name: 'verbose',
        label: 'verbose',
    },
    {
        type: 'boolean',
        name: 'type',
        label: 'csv',
    }
]

const App: React.FC = () => {
    useNavigateHome()

    const [form] = Form.useForm()
    const [formOutput] = Form.useForm()
    const [defaultValues, setDefaultValues] = useState<any>(initDefaultValues)
    const [showOutput, setShowOutput] = useState<boolean>(false)
    const [formError, setFormError] = useState<boolean>(false)

    const [output, setOutput] = useState<any>({
        text: '',
        verbose: '',
        csv: ''
    })
    const [outputFormat, setOutputFormat] = useState<any>({
        'verbose': false,
        'csv': false,
        'download': false
    })
    console.log(outputFormat)
    const [outputLoading, setOutputLoading] = useState<boolean>(false)
    console.log({defaultValues})

    const defaultValuesList = defaultValues.design_options.concat(defaultValues.parameters)
    const defaultValuesNameSequence = defaultValuesList.reduce(
        (obj: any, item: any) => {
            if (item.type === 'sequence')
                obj[item.name] = item.default_value
            return obj
        }, {})
    console.log({defaultValuesNameSequence})

    async function fetchDefaultValues() {
        const data = await fetchBackendToken('/get_default_values')
        if (data)
            setDefaultValues(data)

    }

    async function postValues(values: any) {
        setOutputLoading(true)
        const data = await fetchBackendToken('/get_hybrid_mrna', {
            method: 'POST',
            body: JSON.stringify(values)
        })
        console.log({data})
        setOutput(data)
        setOutputLoading(false)

    }

    useEffect(() => {
        fetchDefaultValues()
    }, [])

    const span_output = 4
    const collapseItems: CollapseProps['items'] = [
        {
            key: '1',
            label: <div style={{color: 'white'}}>Design options</div>,

            children:
                <>
                    <FormMap
                        form={form}
                        defaultValues={defaultValues.design_options}/>
                    <Row
                        gutter={40}
                    >
                        <Col>
                            <Button
                                style={{width: '120%'}}

                                onClick={() => {
                                    form.setFieldsValue({...defaultValuesNameSequence})
                                }}

                            >
                                sample
                            </Button>
                        </Col>
                        <Col>
                            <div/>
                        </Col>
                    </Row>

                </>
        },
        {
            key: '2',
            label: <div style={{color: 'white'}}>Parameters</div>,

            children: <FormMap
                form={form}
                defaultValues={defaultValues.parameters}/>
        },
        {
            key: '3',
            label: <div style={{
                color: showOutput ? 'white' : 'grey'
            }}>Output options</div>,

            collapsible: showOutput ? 'header' : 'disabled',
            children:
                <Form form={formOutput}
                      onValuesChange={(values) => {
                          formOutput.setFieldsValue({values})
                          setOutputFormat(values)

                      }}

                >
                    <Row>
                        <Col span={span_output}>
                            <Form.Item label='verbose' name='verbose'>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={span_output}>
                            <Button
                                onClick={() => {
                                    const blob = new Blob([output.csv], {type: "text/csv;charset=utf-8"});
                                    saveAs(blob, "output.csv")
                                }}
                            >
                                download as csv
                            </Button>
                        </Col>
                    </Row>
                </Form>
        }
    ]

    return (
        <Layout


            style={{backgroundColor: "transparent"}}>
            <Header
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    width: '100%',
                    backgroundColor: colorPrimary,
                }}
            >
                <Row
                    style={{width: '100%'}}

                >
                    <Col span={6}
                         style={{
                             display: 'flex',
                             alignItems: 'center',

                         }}>

                        <Logo style={{
                            height: '60%',
                            display: 'flex',
                            marginTop: '10px',
                            // flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}/>
                    </Col>
                    <Col span={18}
                         style={{
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'end',
                         }}
                    >

                        <LogOut/>
                    </Col>
                </Row>
            </Header>
            <Content
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: "transparent",
                }}
            >
                <Collapse
                    items={collapseItems}
                    expandIcon={({collapsible, isActive}) =>
                        <CaretRightOutlined
                            style={{
                                color: collapsible === 'disabled' ? 'grey' : 'white',
                            }}
                            rotate={isActive ? 90 : 0}/>}
                    defaultActiveKey={['1']}
                    style={{
                        width: '80%',
                        margin: '10px',
                        marginTop: '30px',
                        backgroundColor: colorSecondary,


                    }}
                />
                <Row
                    gutter={40}
                    style={{
                        width: '20%',
                    }}

                >
                    <Col span={12}>
                        <Form form={form} style={{width: '100%'}}
                              onFinishFailed={(values) => {
                                  console.log('error')
                                  setFormError(true)
                              }}
                              onFinish={
                                  async (values) => {
                                      setFormError(false)
                                      console.log('formValues', values)
                                      setShowOutput(true)
                                      await postValues(values)
                                  }

                              }
                        >
                            <Form.Item
                                style={{width: '100%'}}
                                name="submit"
                            >
                                <Button
                                    style={{
                                        width: '100%',
                                        borderColor: formError ? 'red' : colorTextSecondary

                                    }}
                                    type='primary'
                                    htmlType='submit'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Button
                            style={{
                                width: '100%',
                                borderColor: colorPrimary
                            }}
                            onClick={() => {
                                form.resetFields()
                                formOutput.resetFields()
                                setShowOutput(false)
                                setFormError(false)
                            }}
                        >
                            Clear
                        </Button>
                    </Col>
                </Row>
                {//make this clean*
                }
                {formError &&
                    <p style={{
                        color: 'red', backgroundColor: 'white',
                        width: '20%',
                        height: '30px',
                        margin: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        Configuration contains errors
                    </p>
                }


                <div
                    style={{
                        width: '80%',
                        margin: '10px',
                        // marginTop: '30px',

                    }}

                >
                    {/*<Markdown>{'\\*\\*\\*\\*\\*\\*Pluto\\*\\*\\*\\*\\*'}</Markdown>*/}
                    {showOutput &&
                        <CustomSpin loading={outputLoading}>
                            <div style={{
                                fontFamily: 'courier',
                                fontSize: '16px',
                                borderRadius: 4,
                                border: '0.1px solid lightgrey',
                                padding: '10px',
                                wordWrap: "break-word",
                                backgroundColor: 'white'

                            }}>
                                {outputFormat.verbose &&

                                    <Markdown>
                                        {output.verbose}
                                    </Markdown>

                                }
                                <Markdown>
                                    {output.text}
                                </Markdown>
                            </div>

                        </CustomSpin>
                    }
                </div>


            </Content>


        </Layout>

    )

};
export default App;